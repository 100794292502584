body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F5F7FC;
}

code {
    font-family: source-code-pro, monospace;
}

#root {
    min-height: 100vh;
    position: absolute;
    right: 0;
    left: 0;
}

#sidebar-layout, #full-layout {
    position: absolute;
    left: 0;
    right: 0;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    #sidebar-layout {
        margin-left: 300px;
    }

    #header-layout {
        margin-top: 100px !important;
    }

    #sidebar-layout > header > header {
        padding-left: 300px;
    }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1024px) and (max-width: 1280px) {
    #sidebar-layout {
        margin-left: 300px;
    }

    #header-layout {
        margin-top: 100px !important;
    }

    #sidebar-layout > header > header {
        padding-left: 300px;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
    #sidebar-layout {
        margin-left: 300px;
    }

    #header-layout {
        margin-top: 100px !important;
    }

    #sidebar-layout > header > header {
        padding-left: 300px;
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    #header-layout {
        margin-top: 100px !important;
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {

}

